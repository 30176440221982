import React, { FC, useMemo } from 'react';
// import Link from 'next/link';

import { Image } from '../../components/Image';
// import Arrow from '../../components/Arrow';
import styles from './GYChildHelp.module.scss';
import ApplyBtn from '../CountryChild/ApplyBtn';
import { useScreenSize } from '../../components/ScreenSize';
import Arrow from '../../components/Arrow';

interface Props {
  GYHelpChildTitle1: string;
  GYHelpChildTitle2: string;
  GYHelpChildIntro1: string;
  GYHelpChildIntro2: string;
  GYHelpChildData: Array<{ [key: string]: string }>;
  applyBtnConfig: any;
}

const GYChildHelp: FC<Props> = (props) => {
  const {
    GYHelpChildTitle1,
    GYHelpChildTitle2,
    GYHelpChildIntro1,
    GYHelpChildIntro2,
    GYHelpChildData,
    applyBtnConfig,
  } = props;
  const { size } = useScreenSize(); // 页面尺寸属性
  const isMoblie = useMemo(() => {
    if (size.width <= 1024) {
      return true;
    }
    return false;
  }, [size]);
  const handleHelpClick = (link: any) => {
    if (isMoblie) {
      window.location.href = link;
    }
  };

  return (
    <section className={styles['gy-child-help-box']}>
      <h2 className={styles['child-help-title']}>{GYHelpChildTitle1}</h2>
      <h2 className={styles['child-help-title']}>{GYHelpChildTitle2}</h2>
      <div className={styles['child-help-intro-box']}>
        <p className={styles['intro-item']}>{GYHelpChildIntro1}</p>
        <p className={styles['intro-item']}>{GYHelpChildIntro2}</p>
      </div>
      <div className={styles['child-help-btn-box']}>
        <ApplyBtn {...applyBtnConfig} />
      </div>
      <div className={styles['child-help-data-box']}>
        {GYHelpChildData?.map((item, index) => (
          <div key={index} className={styles['child-help-item']} onClick={() => handleHelpClick(item.linkurl)}>
            <div className={styles['item-img-box']}>
              <Image src={item.thumbnail} width={300} height={200} layout="responsive" />
              <div className={styles['item-qr-img-box']}>
                <div className={styles['item-qr-wrap']}>
                  <Image src={item.qrimg} width={120} height={120} layout="responsive" />
                  <div className={styles['item-qr-text']}>{item.qrtext}</div>
                </div>
              </div>
            </div>
            <h2 className={styles['item-title']}>
              {item.title} <Arrow className="h-4 w-4 lg:hidden lg:h-6 lg:w-6" />
            </h2>
            <div className={styles['item-remark']}>{item.remark}</div>
          </div>
        ))}
      </div>
    </section>
  );
};
GYChildHelp.defaultProps = {};

GYChildHelp.displayName = 'GYChildHelp';
export default GYChildHelp;
