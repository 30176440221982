import React from 'react';

import { serverSideTranslation } from '../util/next';
import { getPageContent } from '../api/pagesApi';
import { getPlaygroundData } from '../api/playgroundApi';
import { formatNumForGongyi } from '../util/number';
import { formatIntYearMonth } from '../util/date';
import { getEnv } from '../util/env';
import { catLog } from '../util/Cat';

import Page from '../components/Layout/Page';
import GYTopBanner from '../modules/GYTopBanner';
import PGCharity from '../modules/PGCharity';

import GYChildHelp from '../modules/GYChildHelp';
import GYStaffHelp from '../modules/GYStaffHelp';
import NewsFooter from '../modules/NewsFooter';
import FloatingWindow from '../modules/FloatingWindow';
// import GongyiHomeData from '../data/gongyi';
import { genShareConfig } from '../components/Share/use-config-share';
import { PGContextProvider } from '../components/GongyiProvider/PlaygroundCtxProvider';

interface Props {
  pageContentData: any;
  playGroundCountData: any;
  ENV: string;
  lanType: string;
  _nextI18Next: any;
}

const Gongyi = (props: Props) => {
  const { pageContentData, playGroundCountData, lanType, _nextI18Next: i18n } = props;
  const { pageContent = {}, shareContent = {} } = pageContentData;
  // const {
  //   GYTopBannerData: GYTopBannerDataFromProps,
  //   GYChildHelpData,
  //   GYtaffHelpData,
  //   FloatCardData,
  // } = pageContent || GongyiHomeData;
  // console.log(pageContent);

  const {
    GYTopBannerData: GYTopBannerDataFromProps,
    GYChildHelpData,
    applyBtnConfig,
    PGmodalCardLabel,
    PGModalCardTabs,
    PGCharityNoteData,
    PGCharityNoteCardList,
    PGReflectionData,
    GYtaffHelpData,
    h5jumpData,
    FloatCardData,
  } = pageContent || {}; // GongyiHomeData;
  const GYTopBannerData = { ...GYTopBannerDataFromProps };
  // 处理 Banner 中统计数据
  if (Array.isArray(GYTopBannerData.GYRelatedData)) {
    GYTopBannerData.GYRelatedData = GYTopBannerData.GYRelatedData.map((PGtopAmoutConfigItem: any) => {
      const { key, defaultValue, ...args } = PGtopAmoutConfigItem;
      const { num, unit } = formatNumForGongyi(playGroundCountData[key] || defaultValue, { lanType });
      const amount = num + unit;
      return {
        amount,
        ...args,
      };
    });
  }

  // 处理数据来源
  const nowDate = new Date();
  const dateStr = formatIntYearMonth(
    playGroundCountData.closeYear || nowDate.getFullYear(),
    playGroundCountData.closeMonth || nowDate.getMonth() + 1,
    { locale: lanType },
  );
  const { GYIntroDataSrc } = GYTopBannerData;
  const dateSymbol = '{{date}}';
  if (GYIntroDataSrc?.includes(dateSymbol)) {
    GYTopBannerData.GYIntroDataSrc = GYIntroDataSrc.replace(dateSymbol, dateStr);
  }

  return (
    <PGContextProvider value={{ PGmodalCardLabel, PGModalCardTabs }}>
      <Page title={shareContent.shareTitle}>
        {/* 顶部bannnr模块 */}
        <GYTopBanner {...GYTopBannerData} />
        {/* 善款记录模块 */}
        <div className="bg-[#f7f8f9] pb-[30px] pt-[50px]">
          <PGCharity
            PGCharityNoteData={PGCharityNoteData}
            PGCharityNoteCardList={PGCharityNoteCardList}
            PGReflectionData={PGReflectionData}
          />
        </div>
        <div className="bg-white">
          <div className="flex flex-col space-x-0 bg-white py-[30px] lg:m-auto lg:max-w-screen-xl xl:flex-row xl:space-x-[63px] xl:px-0 xl:py-[100px]">
            {/* 帮扶儿童模块 */}
            <div className="lg:flex-1">
              <GYChildHelp {...GYChildHelpData} applyBtnConfig={applyBtnConfig} />
            </div>
            <div className="lg:flex-1">
              <GYStaffHelp {...GYtaffHelpData} />
            </div>
            {/* 帮扶员工模块 */}
          </div>
        </div>

        <div className="bg-white lg:px-5 xl:px-0">
          <NewsFooter category={1} lanType={i18n.initialLocale} positionNo="charity_1" />
        </div>

        <FloatingWindow h5jumpData={h5jumpData} FloatCardData={FloatCardData} />
      </Page>
    </PGContextProvider>
  );
};
export async function getServerSideProps({ locale, query }: any) {
  const catlog = catLog('PageServer', '/gongyi');
  // /gongyi
  const pageContentParams = {
    uri: '/gongyi',
    pageContentNo: query.pageContentNo,
    preview: query.preview,
  };
  let playGroundCountData;
  let pageContentData;
  try {
    playGroundCountData = await getPlaygroundData()
      .then((res) => {
        const { data } = res || {};
        return data || {};
      })
      .catch(() => ({}));
    pageContentData = await getPageContent(pageContentParams, locale);
    catlog.complete();
  } catch (e: any) {
    catlog.logError('serverRequestError', e);
    catlog.complete();
    throw e;
  }
  return {
    props: {
      lxCustom: {
        cid: 'c_donation_si1gqj93',
        locale,
        ...pageContentData?.shareContent,
      },
      ENV: getEnv(),
      lanType: locale,
      pageContentData,
      playGroundCountData,
      ...genShareConfig(pageContentData),
      ...(await serverSideTranslation(locale, ['cookie', 'common'])),
    },
  };
}
export default Gongyi;
