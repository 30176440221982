import React, { FC } from 'react';
// import Link from 'next/link';
import { useRouter } from 'next/router';
import { Link } from '../../components/Link';
import { Image } from '../../components/Image';
import CommonBanner from '../../components/CommonBanner';
import PGMap from '../PGMap';
import { GyIndexCard, IndexDescription } from '../../components/GyIndexCard';
import { NotSupportWebgl } from '../PGMap/NotSupportWebgl';

import styles from './GYTopBanner.module.scss';

interface Props {
  GYTopImg: string;
  GYIntroLogo: string;
  GYIntroTitleImg: string;
  GYIntroText1: string;
  GYIntroText2: string;
  GYIntroDataSrc: string;
  GYIntroBtn: string;
  GYIntroTitle: string;
  GYIntroBtnLink: string;
  GYRelatedData: Array<{ [key: string]: any }>;
}
const GYTopBanner: FC<Props> = (props) => {
  const {
    GYTopImg,
    GYIntroText1,
    GYIntroText2,
    GYIntroDataSrc,
    GYIntroBtn,
    GYIntroBtnLink,
    GYRelatedData,
    GYIntroTitle,
  } = props;
  const bannerConfig = {
    bgUrl: GYTopImg,
    bgCls: 'h-17.5r lg:h-[1000px] bg-cover bg-top bg-no-repeat',
    cardCls: '-mt-[50px] lg:-mt-[536px]',
    topBannerModel: 'bg',
    enableGradient: true,
  };
  const router = useRouter();
  const { locale = 'zh-CN' } = router;

  const gyTopImgMap: { [key: string]: string } = {
    'zh-HK': 'https://img.meituan.net/smartvenus/a4815e9468576e3e479b5609eed89513170668.png',
    'zh-CN': 'https://img.meituan.net/smartvenus/011787f519f8158a46a60181ca878dc488870.png',
    'en-US': 'https://p0.meituan.net/smartvenus/8b710a21287979643ee9c3a49a53f2e7147238.png',
  };

  const indexData = GYRelatedData.slice(1);
  const gyCurrentData = GYRelatedData[0];

  return (
    <section className={styles['gy-banner-box']}>
      <CommonBanner {...bannerConfig}>
        <div className={styles['gy-info-box']}>
          <div className={styles[`titleimg-box-${locale}`]}>
            <Image src={gyTopImgMap[locale]} layout="fill" objectFit="cover" />
          </div>
          <div className={styles['intro-box']}>
            <p className={styles['intro-item']}>{GYIntroText1}</p>
            <p className={styles['intro-item']}>{GYIntroText2}</p>
          </div>
          {/* <div className={styles['gydata-box']}>
            {GYRelatedData?.map((item, index) => (
              <div key={index} className={styles['gydata-item']}>
                <div>
                  <span className={styles['item-amout']}>{item.amount}</span>
                  <span className={styles['item-unit']}>{item.unit}</span>
                </div>
                <div className={styles['item-remark']}>{item.remark}</div>
              </div>
            ))}
          </div> */}

          <GyIndexCard
            title={GYIntroTitle}
            count={gyCurrentData.amount}
            unit={gyCurrentData.unit}
            index={indexData as any}
          />

          <div className={styles['banner-btn-box']}>
            <Link href={GYIntroBtnLink} legacyBehavior prefetch={false}>
              <a>
                <div className={styles['banner-btn']}>{GYIntroBtn}</div>
              </a>
            </Link>
          </div>
          <div className={styles['map-box']}>
            <NotSupportWebgl>
              <PGMap lanType={locale} />
            </NotSupportWebgl>
          </div>
          <IndexDescription title={GYIntroDataSrc} />
        </div>
      </CommonBanner>
    </section>
  );
};
GYTopBanner.defaultProps = {
  GYTopImg: 'https://p0.meituan.net/smartvenus/3801342aa7c7e2f0f5dce64f09be8f1d533376.png',
  GYIntroLogo: '123',
  GYIntroTitleImg: '',
  GYIntroText1: '美团联合慈善组织带动平台上的公益商家和爱心网友',
  GYIntroText2: '一起帮助乡村儿童快乐奔跑',
  GYIntroDataSrc: '以上数据统计截至2022年8月底',
  GYIntroBtn: '了解详情',
  GYRelatedData: [
    { amount: '384', unit: '座', remark: '操场已建成' },
    { amount: '2.9万', unit: '位', remark: '乡村儿童操场' },
    { amount: '21.3万', unit: '家', remark: '公益商家参与' },
    { amount: '6.5万', unit: '位', remark: '爱心网友参与' },
  ],
};

GYTopBanner.displayName = 'GYTopBanner';
export default GYTopBanner;
