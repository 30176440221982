import React, { FC, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Popup from 'antd-mobile/es/components/popup';
import Toast from 'antd-mobile/es/components/toast';
import copy from 'copy-to-clipboard';
import Arrow from '../../../components/Arrow';

import { Meituan, MeituanEn } from '../../../components/Common/components/Icon';

import styles from './ApplyBtn.module.scss';

interface ApplyPopupProps {
  visible: boolean;
  popupTitle: string;
  popupEmailText: string;
  popupEmail: string;
  popupCancelText: string;
  popupConfirmText: string;
  popupConfirmMsg: string;
  onChange: (visible: boolean) => void;
}

const ApplyPopup: FC<ApplyPopupProps> = (props) => {
  const router = useRouter();
  const { locale = 'zh-CN' } = router;
  const {
    visible,
    popupTitle,
    popupEmail,
    popupEmailText,
    popupCancelText,
    popupConfirmText,
    popupConfirmMsg,
    onChange,
  } = props;

  const renderIcon = (iconProps: any) => {
    if (locale === 'en-US') {
      return <MeituanEn {...iconProps} />;
    }
    return <Meituan {...iconProps} />;
  };
  const IconNode = renderIcon({ fillColor: '#000000', width: 96, height: 24 });

  const onClose = () => onChange(false);
  const onClickCopy = () => {
    onClose();
    copy(popupEmail);
    Toast.show({ content: popupConfirmMsg });
  };

  return (
    <Popup visible={visible} bodyClassName="rounded-t-lg" onMaskClick={onClose}>
      <div className={styles['apply-popup-container']}>
        <div className={styles.mticon}>{IconNode}</div>
        <div className={styles.title}>{popupTitle}</div>
        <div className={styles['email-container']}>
          <div className={styles['email-perfix']}>{popupEmailText}</div>
          <div className={styles['email-text']}>{popupEmail}</div>
        </div>
        <div className={styles['controller-btn']}>
          <button className={styles['btn-item']} onClick={onClose}>
            {popupCancelText}
          </button>
          <button className={classNames(styles['btn-item'], styles.primary)} onClick={onClickCopy}>
            {popupConfirmText}
          </button>
        </div>
      </div>
    </Popup>
  );
};

export interface Props {
  btnText: string;
  link: string;
  popupTitle: string;
  popupEmailText: string;
  popupEmail: string;
  popupCancelText: string;
  popupConfirmText: string;
  popupConfirmMsg: string;
}

const ApplyBtn: FC<Props> = (props) => {
  const { btnText, link, popupTitle, popupEmail, popupEmailText, popupCancelText, popupConfirmText, popupConfirmMsg } =
    props;

  const [popupShowState, setPopupShowState] = useState(false);

  const onClickBtn = () => setPopupShowState(true);

  const popupProps = {
    visible: popupShowState,
    popupTitle,
    popupEmail,
    popupEmailText,
    popupCancelText,
    popupConfirmText,
    popupConfirmMsg,
    onChange: (state: boolean) => setPopupShowState(state),
  };

  return (
    <div className={styles['apply-btn-wrap']}>
      <a className={classNames(styles['apply-btn'], styles.pc)} href={link} target="_blank" rel="noreferrer">
        <span>{btnText}</span>
        <div className={styles['arrow-btn-arrow']}>
          <Arrow className="h-4 w-4 lg:h-8 lg:w-8" />
        </div>
      </a>
      <button className={classNames(styles['apply-btn'], styles.h5)} onClick={onClickBtn}>
        <span>{btnText}</span>
        <div className={styles['arrow-btn-arrow']}>
          <Arrow className="h-4 w-4 lg:h-6 lg:w-6" />
        </div>
      </button>
      {/* <div className={styles['arrow-btn-arrow']}>
        
      </div> */}
      <ApplyPopup {...popupProps} />
    </div>
  );
};

export default ApplyBtn;
