import React, { FC } from 'react';
import { Image } from '../../components/Image';
import Arrow from '../../components/Arrow';

import styles from './GYStaffHelp.module.scss';

interface Props {
  GYHelpStaffTitle: string;
  GYHelpStaffSubtitle: string;
  GYHelpStaffIntro1: string;
  GYHelpStaffIntro2: string;
  GYHelpStaffBtn: string;
  GYHelpStaffBtnLink: string;
  GYHelpStaffData: Array<{ [key: string]: string }>;
}

const GYStaffHelp: FC<Props> = (props) => {
  const {
    GYHelpStaffTitle,
    GYHelpStaffSubtitle,
    GYHelpStaffIntro1,
    GYHelpStaffIntro2,
    GYHelpStaffBtnLink,
    GYHelpStaffBtn,
    GYHelpStaffData,
  } = props;

  return (
    <section className={styles['gy-staff-help-box']}>
      <div className={styles['staff-help-title-box']}>
        <h2 className={styles['staff-help-title']}>{GYHelpStaffTitle}</h2>
        <h2 className={styles['staff-help-title']}>{GYHelpStaffSubtitle}</h2>
      </div>
      <div className={styles['staff-help-intro-box']}>
        <div className={styles['staff-help-intro-item']}>
          <p className={styles['staff-help-intro']}>{GYHelpStaffIntro1}</p>
          <p className={styles['staff-help-intro']}>{GYHelpStaffIntro2}</p>
        </div>
      </div>
      <div className={styles['staff-help-btn-box']}>
        <a href={GYHelpStaffBtnLink} target="_blank" rel="noreferrer">
          <div className={styles['staff-help-btn']}>
            <span>{GYHelpStaffBtn}</span>
            <Arrow className="h-4 w-4 lg:h-8 lg:w-8" />
          </div>
        </a>
      </div>
      <div className={styles['staff-help-data-box']}>
        {GYHelpStaffData?.map((item, index) => (
          <div key={index} className={styles['staff-help-item']}>
            <div className={styles['item-img-box']}>
              <div className={styles['item-img-wrap']}>
                <Image src={item.staffImg} width={300} height={200} layout="responsive" />
              </div>
              <div className={styles['item-content-box']}>
                <div className={styles['item-data-box']}>
                  <div className={styles['item-data']}>
                    <div className={styles['item-amount-box']}>
                      <span className={styles['item-amount']}>{item.amount1}</span>
                      <span className={styles['item-unit']}>{item.unit1}</span>
                    </div>
                    <span className={styles['item-amount-remark']}>{item.amountRemark1}</span>
                  </div>
                  <div className={styles['item-data']}>
                    <div className={styles['item-amount-box']}>
                      <span className={styles['item-amount']}>{item.amount2}</span>
                      <span className={styles['item-unit']}>{item.unit2}</span>
                    </div>
                    <span className={styles['item-amount-remark']}>{item.amountRemark2}</span>
                  </div>
                </div>
                <p className={styles['item-amount-src']}>{item.amountSrc}</p>
              </div>
            </div>

            <div className={styles['item-title']}>
              <a href={item.btnLink} target="_blank" rel="noreferrer">
                <div className={styles['item-btn']}>
                  <span>{item.title}</span>
                  <Arrow className="h-3 w-3 lg:h-6 lg:w-6" />
                </div>
              </a>
            </div>

            {/* <h2 className={styles['item-title']}>{item.title}</h2> */}
            <div className={styles['item-title-remark']}>{item.titleRemark}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

GYStaffHelp.defaultProps = {};

GYStaffHelp.displayName = 'GYStaffHelp';
export default GYStaffHelp;
